<script setup lang="ts">
import { showToast } from 'vant'
import { CommentApi } from '~/net/apis/comment_api'

const onClickLeft = () => history.back()
const message = ref('')
async function submintHandle() {
  const res = await CommentApi.submitFeedback(
    {
      content: message.value
    }
  )
  if (res.code === 200) {
    showToast('提交成功')
    onClickLeft()
  }
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="意见反馈" left-arrow @click-left="onClickLeft" />
    <div mt-4>
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="14"
          autosize
          label=""
          type="textarea"
          maxlength="500"
          placeholder="请输入文字"
          show-word-limit
        />
      </van-cell-group>
      <div mt-4 flex justify-center>
        <div :class="{ disabled: !message }" round h-11 w-88 text-center ct-ffffff class="bg-bt" @click="submintHandle">提交</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content){
  background: #111425;
}
:deep(.van-nav-bar__title){
  color: #D0D0D0
}
:deep(.van-nav-bar .van-icon){
  color: #FFFFFF
}
.bg-bt{
  background: linear-gradient(130.94deg, #FD9403 16.87%, #FD5B03 89.42%);
  line-height: 44px;
  border-radius: 10px;
}
:deep(.van-hairline--bottom:after){
  border-bottom-width: 0;
}
:deep(.van-cell){
  background: #D9D9D9
}
:deep(.van-field__control::placeholder){
  color: #5c5e69;
}
.disabled{
  opacity: 0.6;
  pointer-events: none;
}
</style>
